import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function MainPriceView() {
  const language = useSelector((state: any) => state.language.value)

  return (
    <div className="section main_price">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4 text-center mt-3 mb-5">
            <a className="btn btn-round btn-secondary btn-lg btn-block btn-hand" data-toggle="collapse" href="#collapsePrice" role="button" aria-expanded="false" aria-controls="collapsePrice">
              {language['Easy Travel Care Prices']}
              <span className="icon">
                <img src="/resources/home/img/svg/icon-hand.svg" alt="" />
              </span>
            </a>
          </div>
        </div>
        <div className="collapse" id="collapsePrice">
          <div className="row">
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Shorts']}</span>
                    <h4 className="text-center p-2">
                      2<small>{language['days']} </small>
                      <small className="text-warning">/</small>
                      <small> $</small>10 (₩15,000)
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Shorts']}</span>
                    <h4 className="text-center p-2">
                      3<small>{language['days']} </small> <small className="text-warning">/</small> <small> $</small>10 (₩12,000)
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Shorts']}</span>
                    <h4 className="text-center p-2">
                      4~5<small>{language['days']} </small> <small className="text-warning">/</small> <small> $</small>15 (₩18,000)
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Middle']}</span>
                    <h4 className="text-center p-2">
                      6~14<small>{language['days']} </small> <small className="text-warning">/</small> <small> $</small>25 (₩30,000)
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Middle']}</span>
                    <h4 className="text-center p-2">
                      15~21<small>{language['days']} </small> <small className="text-warning">/</small> <small> $</small>30 (₩36,000)
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Middle']}</span>
                    <h4 className="text-center p-2">
                      22~30<small>{language['days']} </small> <small className="text-warning">/</small> <small> $</small>39 (₩46,000)
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4">
              <Link to="/purchase/information">
                <div className="card">
                  <div className="card-body">
                    <span className="badge badge-pill badge-secondary en">{language['Long']}</span>
                    <h4 className="text-center p-2">
                      31<small>{language['days']}</small> ~
                    </h4>
                    <p className="text-muted text-center en mb-0">{language['별도 문의']}</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-8 d-flex align-items-center justify-content-center">
              <h5>
                {language['31일 이상의 서비스 이용을 원하시면']} <br />
                {language['별도 문의하여 주시기 바랍니다.']}
                <br />
                <strong className="text-lg text-secondary">
                  <i className="bi bi-telephone-fill mr-1"></i>1644-1493
                </strong>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPriceView
