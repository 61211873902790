import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateUtil } from '../../../../utils/date-format'
import axiosConnect from '../../../../network/http-request'
import { useSelector } from 'react-redux'
import EasyTravelCareTermKRView from './terms/EasyTravelCareTermKRView'
import EasyTravelCareTermCNView from './terms/EasyTravelCareTermCNView'
import EasyTravelCareTermJPView from './terms/EasyTravelCareTermJPView'
import EasyTravelCareTermENView from './terms/EasyTravelCareTermENView'
import HyundaiENView from './terms/HyundaiENView'
import HyundaiJPView from './terms/HyundaiJPView'
import HyundaiCNView from './terms/HyundaiCNView'
import HyundaiKRView from './terms/HyundaiKRView'
import { addCommasToNumber } from '../../../../utils/number-format'

function PurchaseInputView() {
  const navigate = useNavigate()
  const language = useSelector((state: any) => state.language.value)

  const serviceDay = _.range(2, 30, 1)
  const [termsAll, setTermsAll] = useState(false)
  const [easyTravelTerms, setEasyTravelTerms] = useState(false)
  const [hyundaiTerms, setHyundaiTerms] = useState(false)

  const [day, setDay] = useState(2)
  const [startDay, setStartDay] = useState(new Date())
  const [endDay, setEndDay] = useState('')
  const [servicePrice, setServicePrice] = useState({
    servicePrice: '',
    servicePriceDollar: '',
  })

  const initDate = (): Date => {
    let today = new Date()
    today.setDate(today.getDate() + 1)
    return today
  }

  const addDays = (date: Date, days: number): Date => {
    let result = new Date(date)
    result.setDate(result.getDate() + (days - 1))
    return result
  }

  const calculateServicePrice = () => {
    axiosConnect(`/rest/purchase/getServiceInfo`, 'GET', {}, { term: day })
      .then((result) => {
        if (result.data != null) {
          setServicePrice(result.data)
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  const termsAllChange = () => {
    setTermsAll(!termsAll)
    setEasyTravelTerms(!termsAll)
    setHyundaiTerms(!termsAll)
  }

  useEffect(() => {
    setTermsAll(easyTravelTerms && hyundaiTerms)
  }, [easyTravelTerms, hyundaiTerms, termsAll])

  useEffect(() => {
    calculateServicePrice()
  }, [day])

  useEffect(() => {
    setEndDay(DateUtil.convertDate(addDays(startDay, day)))
  }, [day, startDay])

  useEffect(() => {
    setStartDay(initDate())
  }, [])

  const nextPage = () => {
    if (!termsAll) {
      alert(language['약관에 동의해주시기 바랍니다.'])
      return
    }
    navigate('/purchase/payment', {
      state: {
        easyTravelTerms: easyTravelTerms,
        hyundaiTerms: hyundaiTerms,
        day: day,
        priceKr: servicePrice.servicePrice,
        priceEn: servicePrice.servicePriceDollar,
        startDay: DateUtil.convertDate(startDay),
        endDay: endDay,
      },
    })
  }

  const getEasyTravelCareTerm = () => {
    if (language.code === 'en') {
      return <EasyTravelCareTermENView />
    } else if (language.code === 'jp') {
      return <EasyTravelCareTermJPView />
    } else if (language.code === 'cn') {
      return <EasyTravelCareTermCNView />
    } else {
      return <EasyTravelCareTermKRView />
    }
  }

  const getHyundaiTerm = () => {
    if (language.code === 'en') {
      return <HyundaiENView />
    } else if (language.code === 'jp') {
      return <HyundaiJPView />
    } else if (language.code === 'cn') {
      return <HyundaiCNView />
    } else {
      return <HyundaiKRView />
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12">
          <form className="form-add">
            <section>
              <h5 className="mt-2">{language['서비스 제공 시간 안내']}</h5>
              <div className="boxwrap">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <ul className="list">
                          <li>
                            <strong>{language['서비스 개시일 24시간(업무일 기준) 전까지 구매 가능']}</strong> 
                          </li>
                          <li>
                            <strong>{language['서비스 제공 기간: 개시일 00시부터 종료일 23:59분까지']}</strong>
                          </li>
                          <li>{language['31일 이상의 서비스 이용을 원하시면 별도 문의하여 주시기 바랍니다. (1644-1493)']}</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section>
              <h5 className="mt-5">
                {language['서비스 이용 기간 설정']}
                <strong className="required"></strong>
              </h5>
              <hr className="dark" />
              <div className="form-group row align-items-center">
                <label className="col-md-2 col-form-label">{language['가입기간']}</label>
                <div className="col-md-4">
                  <select className="custom-select" id="selRegion" value={day} onChange={(e) => setDay(parseInt(e.target.value))}>
                    {serviceDay.map((day: any, index: any) => {
                      return (
                        <option key={index} value={day}>
                          {day} {language['일']}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <label className="col-md-2 col-form-label">{language['이용금액']}</label>
                <div className="col-md-4">
                  <input disabled type="text" className="form-control" value={`$${servicePrice.servicePriceDollar} (₩${addCommasToNumber(servicePrice.servicePrice)})`} />
                </div>
              </div>
              <hr />
              <div className="form-group row align-items-center">
                <label className="col-md-2 col-form-label">{language['시작일']}</label>
                <div className="col-md-4">
                  <DatePicker minDate={initDate()} className="input-cal form-control form-date" id="selStartDate" dateFormat="yyyy-MM-dd" selected={startDay} onChange={(date: Date) => setStartDay(date)} />
                </div>
                <label className="col-md-2 col-form-label">{language['종료일']}</label>
                <div className="col-md-4">
                  <input disabled data-date-format="yyyy-mm-dd" className="input-cal form-control form-date" id="selEndDate" value={endDay} style={{ width: '130px !important' }} />
                </div>
              </div>
              <hr className="dark mb-0" />
            </section>

            <section>
              <h5 className="mt-5">
                {language['약관동의']}
                <strong className="required"></strong>
              </h5>
              <hr className="mfull dark" />
              <div className="form-group row align-items-center">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" id="customCheckboxInlineA" name="customCheckboxInlineA" className="custom-control-input" checked={termsAll} onChange={termsAllChange} />
                    <label className="custom-control-label" htmlFor="customCheckboxInlineA">
                      <strong>{language['아래의 약관을 모두 동의합니다.']}</strong>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <h6>{language['이지트래블케어 이용 약관']}</h6>
                  <div className="form-group border terms">{getEasyTravelCareTerm()}</div>
                  <div className="form-group row align-items-center">
                    <div className="col-12">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="customCheckboxInlineT" name="customCheckboxInlineT" className="custom-control-input" checked={easyTravelTerms} onChange={() => setEasyTravelTerms(!easyTravelTerms)} />
                        <label className="custom-control-label" htmlFor="customCheckboxInlineT">
                          <strong>{language['이지트래블케어 이용 약관에 동의합니다.']}</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <h6>{language['현대해상 화재보험 약관']}</h6>
                  <div className="form-group border terms">{getHyundaiTerm()}</div>
                  <div className="form-group row align-items-center">
                    <div className="col-12">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" id="customCheckboxInlineH" name="customCheckboxInlineH" className="custom-control-input" checked={hyundaiTerms} onChange={() => setHyundaiTerms(!hyundaiTerms)} />
                        <label className="custom-control-label" htmlFor="customCheckboxInlineH">
                          <strong>{language['현대해상 화재보험 약관에 동의합니다.']}</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mfull dark mb-0" />
            </section>

            <section>
              <h5 className="mt-5">{language['서비스 이용료']}</h5>
              <hr className="mfull dark mt-0 mb-0" />

              <div className="boxwrap">
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{language['사용기간']}</th>
                      <th scope="col">{language['금액']}</th>
                      <th scope="col">{language['사용기간']}</th>
                      <th scope="col">{language['금액']}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>2 {language['Days']} </th>
                      <td className="table-primary">
                        <strong>$10 (₩15,000)</strong>
                      </td>
                      <th>6~14 {language['Days']} </th>
                      <td className="table-primary">
                        <strong>$25 (₩30,000)</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>3 {language['Days']} </th>
                      <td className="table-primary">
                        <strong>$10 (₩12,000)</strong>
                      </td>
                      <th>15~21 {language['Days']}</th>
                      <td className="table-primary">
                        <strong>$30 (₩36,000)</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>4~5 {language['Days']}</th>
                      <td className="table-primary">
                        <strong>$15 (₩18,000)</strong>
                      </td>
                      <th>22~30 {language['Days']} </th>
                      <td className="table-primary">
                        <strong>$39 (₩46,000)</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </form>

          <div className="text-center pt-4">
            <a onClick={nextPage} className="btn btn-primary btn-lg">
              {language['다음']}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PurchaseInputView
